.style {
  display: flex;
  align-items: center;

  flex-direction: column;

  font-size: 5em;
  -webkit-text-stroke: 1px white;
  -webkit-text-fill-color: #64789c;
  -webkit-animation: fill 0.5s infinite alternate;
  background: #f6f7f8;

  overflow-x: hidden;
  justify-content: center;
}

.animl {
  width: fit-content;
  white-space: nowrap;
  animation: example 10s linear 0.5s infinite;
}
.animr {
  width: fit-content;
  white-space: nowrap;
  animation: example2 10s linear 0.5s infinite;
}

@keyframes example2 {
  0% {
    margin-right: 0%;
    opacity: 0%;
  }
  10% {
    opacity: 100%;
  }
  90% {
    opacity: 100%;
  }
  100% {
    margin-right: -100%;
    opacity: 0%;
  }
}
@keyframes example {
  0% {
    margin-left: 0%;
    opacity: 0%;
  }
  10% {
    opacity: 100%;
  }
  90% {
    opacity: 100%;
  }
  100% {
    margin-left: -100%;
    opacity: 0%;
  }
}
