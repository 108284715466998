@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes scroll-reverse {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.animate-scroll {
  animation: scroll 10s linear infinite;
}

.animate-scroll-reverse {
  animation: scroll-reverse 10s linear infinite;
}
/* Custom animations or gradient backgrounds */
.scroll__text::before {
  content: "";
  width: 100%;
  height: 50vh;
  background: linear-gradient(rgba(255, 255, 255, 0), white);
  position: absolute;
  top: 0;
  transform: translateY(calc(-100% + 1px));
}

.rectangle {
  transition: background 1s cubic-bezier(0.41, 1.68, 0.55, 0.89);
}
.typingtext {
  white-space: nowrap; /*Evitar saltos de línea*/
  overflow: hidden;
  width: 30em;
  animation: typing 10s steps(500);
  animation-iteration-count: infinite;
}

@keyframes typing {
  from {
    width: 0px;
  }
}

@keyframes moveHorizontal {
  0%,
  100% {
    background-position-x: 0;
  }
  50% {
    background-position-x: 20px;
  }
}
